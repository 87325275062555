import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import {
  removeItem,
  requestGetItems,
  requestPutItem,
  requestSetNote,
  requestChangeQty,
} from 'actions/shopping-cart';
import createLoadingSelector from './create-loading-selector';

const stateShoppingCart = (state) => state.shoppingCart;

export const getAllItems = createSelector(
  stateShoppingCart,
  (shoppingCart) => shoppingCart.getItems
);

export const getDiscountItem = createSelector(
  stateShoppingCart,
  (shoppingCart) => shoppingCart.discountItem
);

export const getOpenCart = createSelector(
  stateShoppingCart,
  (shoppingCart) => shoppingCart.openCart
);

export const getProcedures = createSelector(getAllItems, (items) => {
  const procedures = Object.values(
    items.procedureList || items.Procedure || []
  );
  return orderBy(procedures, ['cartOrder', 'orderBY'], ['desc', 'desc']);
});

export const getCartStatus = createSelector(
  getAllItems,
  (items) => items.status || null
);

export const getTotalPrice = createSelector(
  getAllItems,
  (items) => items.total_price || 0
);

export const getPromotionalDiscount = createSelector(
  getAllItems,
  (items) => items.promotionalDiscount || 0
);

export const getOldProcedureName = createSelector(
  getAllItems,
  (items) => items.oldProcedureName || ''
);

export const getFrequentlyProcedures = createSelector(
  getAllItems,
  (items) => items.frequentlyProcedures || null
);

export const getSelectedPatientCart = createSelector(
  getAllItems,
  (items) => items.selectedPatientCart || null
);

export const getMppxIdes = createSelector(getAllItems, (items) =>
  items.mppxIds ? Object.keys(items.mppxIds).map((item) => +item) : []
);

export const getPutItem = createSelector(
  getAllItems,
  (items) => !!items.putItem
);

export const getCurrentMppxId = createSelector(
  getAllItems,
  (items) => items.currentMppxId || null
);

export const getQuantity = createSelector(getAllItems, (item) => item.quantity);

export const cartNotEmpty = createSelector(
  getQuantity,
  (item) => !!(item && item > 0)
);

export const createGetItemsLoading = createLoadingSelector(
  requestGetItems.actionName
)();

export const createRemoveItemLoading = createLoadingSelector(
  removeItem.actionName
)();

export const createPutItemLoading = (state, params) =>
  createLoadingSelector(requestPutItem.actionName)(params)(state);

export const getContactInformation = createSelector(
  stateShoppingCart,
  (shopingCart) => ({
    hospitalContactPerson: shopingCart.hospitalContactPerson,
    hospitalPhoneNumber: shopingCart.hospitalPhoneNumber,
  })
);

export const createSetNoteLoading = createLoadingSelector(
  requestSetNote.actionName
)();

export const getNote = createSelector(getAllItems, (getItems) => getItems.note);

export const getGoodFaithEnabled = createSelector(
  getAllItems,
  (getItems) => getItems.isGFE
);

export const getGroupId = createSelector(
  getAllItems,
  (getItems) => getItems.group_id
);

export const getInsurance = createSelector(
  getAllItems,
  (getItems) => getItems.insurance
);

export const createChangeQtyLoading = createLoadingSelector(
  requestChangeQty.actionName
)();
